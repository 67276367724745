import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Card, Container, Navbar, Row, Col, Toast, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = ({ onLogout }) => (
  <Navbar bg="primary" variant="dark" className="mb-4">
    <Container>
      <Navbar.Brand>Banza</Navbar.Brand>
      <Button variant="outline-light" size="sm" onClick={onLogout}>Cerrar Sesión</Button>
    </Container>
  </Navbar>
);

const WalletCard = ({ balance }) => (
  <Card className="text-center mb-4">
    <Card.Body>
      <Card.Title>Tu Saldo</Card.Title>
      <Card.Text className="display-4 text-primary">
        ${balance.toFixed(2)}
      </Card.Text>
    </Card.Body>
  </Card>
);

const TransactionHistory = ({ transactions }) => (
  <Card className="mb-4">
    <Card.Header>Historial de Transacciones</Card.Header>
    <Card.Body>
      {transactions.slice(-5).reverse().map((transaction, index) => (
        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <strong>{transaction.type}</strong> - {transaction.recipient}
          </div>
          <div className={transaction.amount < 0 ? 'text-danger' : 'text-success'}>
            ${Math.abs(transaction.amount).toFixed(2)}
          </div>
        </div>
      ))}
    </Card.Body>
  </Card>
);

const MFAFlow = () => {
  const [step, setStep] = useState('login');
  const [showMFA, setShowMFA] = useState(false);
  const [riskLevel, setRiskLevel] = useState('bajo');
  const [mfaMethod, setMfaMethod] = useState('app');
  const [rememberDevice, setRememberDevice] = useState(false);
  const [balance, setBalance] = useState(10000);
  const [transferAmount, setTransferAmount] = useState('');
  const [transferRecipient, setTransferRecipient] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [errors, setErrors] = useState({});
  const [pendingAction, setPendingAction] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [transactions, setTransactions] = useState([
    { date: '2023-08-07', type: 'Depósito', amount: 5000, recipient: 'Cuenta Propia', balanceAfter: 15000 },
    { date: '2023-08-08', type: 'Transferencia', amount: -200, recipient: 'Juan Pérez', balanceAfter: 14800 },
    { date: '2023-08-09', type: 'Compra', amount: -50, recipient: 'Tienda XYZ', balanceAfter: 14750 },
    { date: '2023-08-10', type: 'Transferencia', amount: -500, recipient: 'María García', balanceAfter: 14250 },
    { date: '2023-08-11', type: 'Depósito', amount: 1000, recipient: 'Cuenta Propia', balanceAfter: 15250 },
  ]);

  const recipients = [
    { id: '1', name: 'Juan Pérez' },
    { id: '2', name: 'María García' },
    { id: '3', name: 'Carlos Rodríguez' },
  ];

  useEffect(() => {
    setBalance(transactions[transactions.length - 1].balanceAfter);
  }, [transactions]);

  const showToast = (message) => {
    setNotificationMessage(message);
    setShowNotification(true);
  };

  const validateForm = (formType) => {
    const newErrors = {};
    switch (formType) {
      case 'login':
        if (!email) newErrors.email = "El correo electrónico es requerido";
        if (!password) newErrors.password = "La contraseña es requerida";
        break;
      case 'mfa':
        if (!mfaCode && mfaMethod !== 'biometric') newErrors.mfaCode = "El código 2FA es requerido";
        break;
      case 'transfer':
        if (!transferAmount) newErrors.transferAmount = "El monto es requerido";
        else if (parseFloat(transferAmount) > balance) newErrors.transferAmount = "Saldo insuficiente";
        if (!transferRecipient) newErrors.transferRecipient = "Selecciona un destinatario";
        break;
      case 'changePassword':
        if (!newPassword) newErrors.newPassword = "La nueva contraseña es requerida";
        if (!confirmPassword) newErrors.confirmPassword = "Confirma la nueva contraseña";
        if (newPassword !== confirmPassword) newErrors.confirmPassword = "Las contraseñas no coinciden";
        break;
      case 'updateContact':
        if (!newEmail) newErrors.newEmail = "El nuevo email es requerido";
        if (!newPhone) newErrors.newPhone = "El nuevo teléfono es requerido";
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleMFA = (action) => {
    if (validateForm('mfa')) {
      setShowMFA(false);
      switch (action) {
        case 'login':
          completeLogin();
          break;
        case 'transfer':
          completeTransfer();
          break;
        case 'changePassword':
          completeChangePassword();
          break;
        case 'updateContact':
          completeUpdateContact();
          break;
        default:
          break;
      }
      setPendingAction(null);
    }
  };

  const handleAction = (action) => {
    if (validateForm(action)) {
      switch (action) {
        case 'login':
          if (riskLevel === 'bajo' && rememberDevice) {
            completeLogin();
          } else {
            setShowMFA(true);
            setPendingAction('login');
          }
          break;
        case 'transfer':
          if (riskLevel === 'alto' || (riskLevel === 'medio' && parseFloat(transferAmount) > 1000)) {
            setShowMFA(true);
            setPendingAction('transfer');
          } else {
            completeTransfer();
          }
          break;
        case 'changePassword':
        case 'updateContact':
          setShowMFA(true);
          setPendingAction(action);
          break;
        default:
          break;
      }
    }
  };

  const completeLogin = () => {
    setStep('dashboard');
    showToast('Inicio de sesión exitoso');
  };

  const completeTransfer = () => {
    const amount = parseFloat(transferAmount);
    const newBalance = balance - amount;
    const newTransaction = {
      date: new Date().toISOString().split('T')[0],
      type: 'Transferencia',
      amount: -amount,
      recipient: recipients.find(r => r.id === transferRecipient).name,
      balanceAfter: newBalance
    };
    setTransactions([...transactions, newTransaction]);
    setBalance(newBalance);
    showToast(`Transferencia de $${amount.toFixed(2)} completada con éxito!`);
    setStep('dashboard');
    setTransferAmount('');
    setTransferRecipient('');
  };

  const completeChangePassword = () => {
    showToast('Contraseña cambiada con éxito!');
    setStep('dashboard');
    setNewPassword('');
    setConfirmPassword('');
  };

  const completeUpdateContact = () => {
    showToast('Información de contacto actualizada con éxito!');
    setStep('dashboard');
    setNewEmail('');
    setNewPhone('');
  };

  const handleLogout = () => {
    setStep('login');
    setEmail('');
    setPassword('');
    setRememberDevice(false);
    showToast('Has cerrado sesión correctamente');
  };

  const renderForm = (formType) => {
    switch (formType) {
      case 'login':
        return (
          <Form>
            <Form.Group className="mb-3">
              <Form.Control 
                type="email" 
                placeholder="Correo electrónico" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control 
                type="password" 
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Check 
              type="switch"
              id="remember-device"
              label="Recordar este dispositivo"
              checked={rememberDevice}
              onChange={(e) => setRememberDevice(e.target.checked)}
            />
          </Form>
        );
      case 'mfa':
        return (
          <Form>
            <Form.Group className="mb-3">
              <Form.Select value={mfaMethod} onChange={(e) => setMfaMethod(e.target.value)}>
                <option value="app">App Autenticador</option>
                <option value="sms">SMS</option>
                <option value="email">Correo Electrónico</option>
                <option value="biometric">Biométrico</option>
              </Form.Select>
            </Form.Group>
            {mfaMethod !== 'biometric' && (
              <Form.Group className="mb-3">
                <Form.Control 
                  type="text" 
                  placeholder="Ingresa el código 2FA"
                  value={mfaCode}
                  onChange={(e) => setMfaCode(e.target.value)}
                  isInvalid={!!errors.mfaCode}
                />
                <Form.Control.Feedback type="invalid">{errors.mfaCode}</Form.Control.Feedback>
              </Form.Group>
            )}
            {mfaMethod === 'biometric' && <Button className="w-100">Escanear Huella Dactilar</Button>}
          </Form>
        );
      case 'transfer':
        return (
          <Form>
            <Form.Group className="mb-3">
              <Form.Select 
                value={transferRecipient} 
                onChange={(e) => setTransferRecipient(e.target.value)}
                isInvalid={!!errors.transferRecipient}
              >
                <option value="">Selecciona el destinatario</option>
                {recipients.map(recipient => (
                  <option key={recipient.id} value={recipient.id}>{recipient.name}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.transferRecipient}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control 
                type="number" 
                placeholder="Monto" 
                value={transferAmount} 
                onChange={(e) => setTransferAmount(e.target.value)}
                isInvalid={!!errors.transferAmount}
              />
              <Form.Control.Feedback type="invalid">{errors.transferAmount}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        );
      case 'changePassword':
        return (
          <Form>
            <Form.Group className="mb-3">
              <Form.Control 
                type="password" 
                placeholder="Nueva Contraseña" 
                value={newPassword} 
                onChange={(e) => setNewPassword(e.target.value)}
                isInvalid={!!errors.newPassword}
              />
              <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control 
                type="password" 
                placeholder="Confirmar Nueva Contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        );
      case 'updateContact':
        return (
          <Form>
            <Form.Group className="mb-3">
              <Form.Control 
                type="email" 
                placeholder="Nuevo Email" 
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                isInvalid={!!errors.newEmail}
              />
              <Form.Control.Feedback type="invalid">{errors.newEmail}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control 
                type="tel" 
                placeholder="Nuevo Teléfono"
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
                isInvalid={!!errors.newPhone}
              />
              <Form.Control.Feedback type="invalid">{errors.newPhone}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        );
      default:
        return null;
    }
  };

  const renderCard = (title, content, onSubmit, submitText, onCancel) => (
    <Card>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        {content}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        {onCancel && <Button variant="secondary" onClick={onCancel}>Volver</Button>}
        <Button onClick={onSubmit}>{submitText}</Button>
      </Card.Footer>
    </Card>
  );

  const renderDashboard = () => (
    <>
      <WalletCard balance={balance} />
      <Row>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Transferir</Card.Title>
              <Button className="w-100" onClick={() => setStep('transfer')}>Transferir Fondos</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Seguridad</Card.Title>
              <Button className="w-100" onClick={() => setShowSecurityModal(true)}>Opciones de Seguridad</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Contacto</Card.Title>
              <Button className="w-100" onClick={() => setStep('updateContact')}>Actualizar Contacto</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Configuración</Card.Title>
              <Button className="w-100" variant="secondary">Ajustes</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <TransactionHistory transactions={transactions} />
    </>
  );

  const renderStep = () => {
    switch (step) {
      case 'login':
        return renderCard("Iniciar Sesión", renderForm('login'), () => handleAction('login'), "Iniciar Sesión");
      case 'dashboard':
        return renderDashboard();
      case 'transfer':
        return renderCard("Transferir Fondos", <>
          <WalletCard balance={balance} />
          {renderForm('transfer')}
        </>, () => handleAction('transfer'), "Transferir", () => setStep('dashboard'));
      case 'changePassword':
        return renderCard("Cambiar Contraseña", renderForm('changePassword'), () => handleAction('changePassword'), "Cambiar Contraseña", () => setStep('dashboard'));
      case 'updateContact':
        return renderCard("Actualizar Información de Contacto", renderForm('updateContact'), () => handleAction('updateContact'), "Actualizar", () => setStep('dashboard'));
      default:
        return null;
    }
  };

  return (
    <Container className="mt-4">
      <Header onLogout={handleLogout} />
      <Alert variant="info" className="mb-4">
        <Alert.Heading>MVP MFA</Alert.Heading>
        <p>Los casos base analizados son:</p>
        <ul>
          <li><strong>Inicio de sesión:</strong> 
            <ul>
              <li><strong>Riesgo bajo:</strong> Requiere autenticación de un factor (contraseña). Si "Recordar dispositivo" está activado, no requiere MFA adicional.</li>
              <li><strong>Riesgo medio y alto:</strong> Siempre requiere MFA completo, incluso si "Recordar dispositivo" está activado.</li>
            </ul>
          </li>
          <li><strong>Transferencia de fondos:</strong> 
            <ul>
              <li><strong>Riesgo bajo:</strong> No requiere MFA adicional.</li>
              <li><strong>Riesgo medio:</strong> Requiere MFA para montos superiores a $1,000.</li>
              <li><strong>Riesgo alto:</strong> Siempre requiere MFA, independientemente del monto.</li>
            </ul>
          </li>
          <li><strong>Cambio de contraseña:</strong> Siempre requiere MFA, independientemente del nivel de riesgo.</li>
          <li><strong>Actualización de información de contacto:</strong> Siempre requiere MFA, independientemente del nivel de riesgo.</li>
        </ul>
      </Alert>
      <Form.Group className="mb-4">
        <Form.Label>Simular Nivel de Riesgo:</Form.Label>
        <Form.Select value={riskLevel} onChange={(e) => setRiskLevel(e.target.value)}>
          <option value="bajo">Bajo</option>
          <option value="medio">Medio</option>
          <option value="alto">Alto</option>
        </Form.Select>
      </Form.Group>
      {showMFA 
        ? renderCard("Verificación 2FA", renderForm('mfa'), () => handleMFA(pendingAction), "Verificar")
        : renderStep()
      }

      <Toast 
        show={showNotification} 
        onClose={() => setShowNotification(false)} 
        delay={3000} 
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999,
          minWidth: '250px'
        }}
      >
        <Toast.Header className="bg-primary text-white">
          <strong className="me-auto">Notificación</strong>
        </Toast.Header>
        <Toast.Body className="bg-light">{notificationMessage}</Toast.Body>
      </Toast>

      <Modal show={showSecurityModal} onHide={() => setShowSecurityModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Opciones de Seguridad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button className="w-100 mb-2" onClick={() => {
            setShowSecurityModal(false);
            setStep('changePassword');
          }}>Cambiar Contraseña</Button>
          <Button className="w-100 mb-2">Configurar 2FA</Button>
          <Button className="w-100">Ver Dispositivos Conectados</Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default MFAFlow;  